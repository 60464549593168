

















































































































import { Component, Vue, Watch } from 'vue-property-decorator';

import {
  StoreType,
  City,
  StoreAccount,
  Country,
  Nullable, StoreContractType,
} from '@/app/shared/utilities/static-types';
import { STORE_CONTRACT_TYPES, STORE_TYPES } from '@/app/shared/utilities/object-factory';
import {
  getCitiesByCountryCodeAction,
  getCountriesAction,
} from '@globalActions/DevicesActions';
import { loadStoreAccountAction, updateStoreAccountAction } from '@adminStores/shared/actions';
import FormComponent from '@/app/shared/components/FormComponent.vue';
import storeRules from '@adminStores/shared/validation-rules';
import rules from '@/app/shared/validation-rules';

@Component({
  components: {
    FormComponent,
  },
  data: (vm: any) => ({
    emailRules: [
      rules.required(vm.$i18n.t('field_required')),
      rules.emailFormat(vm.$i18n.t('invalid_email')),
    ],
    storeNameRules: [rules.required(vm.$i18n.t('field_required'))],
    storeTypeRules: [rules.required(vm.$i18n.t('field_required'))],
    brandNameRules: [rules.required(vm.$i18n.t('field_required'))],
    contractTypeRules: [rules.required(vm.$i18n.t('field_required'))],
    storeOwnerShareRules: [
      rules.required(vm.$i18n.t('field_required')),
      rules.onlyNumbers(vm.$i18n.t('only_numbers_allowed')),
    ],
    countriesRules: [rules.required(vm.$i18n.t('field_required'))],
    headQuarterRules: [rules.required(vm.$i18n.t('field_required'))],
    branchesCountRules: [
      rules.required(vm.$i18n.t('field_required')),
      rules.onlyNumbers(vm.$i18n.t('only_numbers_allowed')),
    ],
    contactPhoneRules: [
      rules.saudiPhoneFormat(vm.$i18n.t('phone_not_saudi_valid')),
    ],
    contactEmailRules: [
      rules.emailFormat(vm.$i18n.t('invalid_email')),
    ],
  }),
})
export default class EditStoreOwner extends Vue {
  public storeAccount: Nullable<StoreAccount> = null;
  public email = '';

  public storeName = '';
  public storeType = '';
  public storeNameErrors: string | string[] = [];

  public brandName = '';
  public brandNameErrors: string | string[] = [];

  public selectedStoreType: Nullable<StoreType> = null;
  public storeTypes = STORE_TYPES;

  public selectedContractType: Nullable<Omit<StoreContractType, 'SHARE'>> = null;
  public contractTypes = STORE_CONTRACT_TYPES;
  public storeOwnerShare = 0;

  public selectedCountry: Nullable<Country> = null;
  public countryArray: Country[] = [];
  public get countries(): Country[] {
    return this.countryArray!
      .map((country) => ({
        ...country,
        TEXT: this.$t(`countries.${country.DISPLAY_NAME}`).toString(),
      } as Country));
  }

  public selectedCity: Nullable<City> = null;
  public cityArray: City[] = [];
  public get cities(): City[] {
    return this.cityArray!
      .map((city) => ({
        ...city,
        TEXT: this.$t(`cities.${city.DISPLAY_NAME}`).toString(),
      } as City));
  }

  public contactName: Nullable<string> = '';
  public contactNameRules = [];

  public contactPhone: Nullable<string> = '';

  public contactEmail: Nullable<string> = '';

  public branchesCount: Nullable<number> = 0;

  public async initForm() {
    await this.loadCountries();
    await this.loadStoreAccount();
  }

  public loadCountries() {
    this.countryArray = getCountriesAction();
  }

  public loadCities(country: Country) {
    this.cityArray = getCitiesByCountryCodeAction(country);
  }

  public get storeAccountId() {
    return this.$route.params.id;
  }

  public async loadStoreAccount() {
    this.storeAccount = await loadStoreAccountAction(this.storeAccountId);

    if (!this.storeAccount) {
      this.$router.back();
      return;
    }

    this.storeName = this.storeAccount.STORE_NAME;
    this.brandName = this.storeAccount.BRAND_NAME;
    this.selectedStoreType = this.storeAccount.STORE_TYPE;

    if (this.storeAccount.CONTRACT_TYPE) {
      this.selectedContractType = this.storeAccount.CONTRACT_TYPE;
      this.storeOwnerShare = this.storeAccount.CONTRACT_TYPE.SHARE;
    }

    if (this.storeAccount.CONTACT_PERSON) {
      this.contactName = this.storeAccount.CONTACT_PERSON!.NAME;
      this.contactPhone = this.storeAccount.CONTACT_PERSON!.PHONE;
      this.contactEmail = this.storeAccount.CONTACT_PERSON!.EMAIL;
    }

    this.selectedCountry = this.storeAccount.HEADQUARTER_COUNTRY;
    this.selectedCity = this.storeAccount.HEADQUARTER_CITY;
    this.email = this.storeAccount.EMAIL;
    this.branchesCount = this.storeAccount.BRANCHES_COUNT;
  }

  public async updateStoreAccount() {
    const storeAccount: StoreAccount = {
      ...this.storeAccount,
      STORE_NAME: this.storeName,
      BRAND_NAME: this.brandName,
      STORE_TYPE: this.selectedStoreType!,
      CONTRACT_TYPE: {
        ...this.selectedContractType!,
        SHARE: this.storeOwnerShare,
      },
      HEADQUARTER_COUNTRY: this.selectedCountry!,
      HEADQUARTER_CITY: this.selectedCity!,
      EMAIL: this.email,
      CONTACT_PERSON: {
        NAME: this.contactName || '',
        PHONE: this.contactPhone || '',
        EMAIL: this.contactEmail || '',
      },
      BRANCHES_COUNT: this.branchesCount || 0,
    };

    await updateStoreAccountAction(storeAccount);
    this.$router.back();
  }

  @Watch('brandName')
  public async onBrandNameChanged(val: string, oldVal: string) {
    if (val !== this.storeAccount!.BRAND_NAME) {
      this.brandNameErrors = await storeRules.duplicateBrandName(val);
    }
  }

  @Watch('$root.$i18n.locale')
  public onChangeLocale(val: string, oldVal: string) {
    const [ prevSelectedCountry, prevSelectedCity ] = [ this.selectedCountry, this.selectedCity ];

    this.loadCountries();

    if (prevSelectedCountry) {
      this.selectedCountry = this.countries.find((country) => country.GEONAME_ID === prevSelectedCountry.GEONAME_ID)!;
      this.loadCities(prevSelectedCountry);
    }

    if (prevSelectedCity) {
      this.selectedCity = this.cities.find((city) => city.GEONAME_ID === prevSelectedCity.GEONAME_ID)!;
    }
  }
}
