import { FirebaseAppFirestore } from '@/app/shared/firebase/firebase-app';
import firebaseNames from '@/app/shared/utilities/firebase-names';

export default {
  async duplicateStoreName(value: string) {
    if (!value) {
      return [];
    }
    const input = value.trim();
    const document = await FirebaseAppFirestore
      .collection(firebaseNames.STORES)
      .where('STORE_NAME', '==', input).get();
    return document.empty ? [] : 'A Store with the same name already exists.';
  },
  async duplicateBrandName(value: string) {
    if (!value) {
      return [];
    }
    const input = value.trim();
    const document = await FirebaseAppFirestore
      .collection(firebaseNames.STORES)
      .where('BRAND_NAME', '==', input).get();
    return document.empty ? [] : 'A Store with the same brand name already exists.';
  },
};
